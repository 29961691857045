/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API V5
 * OpenAPI spec version: v5
 */
import {
  useMutation
} from '@tanstack/react-query'
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult
} from '@tanstack/react-query'
import type {
  ForbiddenErrorObject,
  PostApiV5FirmsWorkflowRequestsBulkDispatch200,
  PostApiV5FirmsWorkflowRequestsBulkDispatchBody
} from '.././schemas'
import postApiV5FirmsWorkflowRequestsBulkDispatchMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5FirmsWorkflowRequestsBulkDispatchErrorType } from '../../configOrvalAPIV5';



/**
 * @summary Dispatch one or many requests
 */
export const postApiV5FirmsWorkflowRequestsBulkDispatch = (
    postApiV5FirmsWorkflowRequestsBulkDispatchBody: PostApiV5FirmsWorkflowRequestsBulkDispatchBody,
 ) => {
      
      
      return postApiV5FirmsWorkflowRequestsBulkDispatchMutator<PostApiV5FirmsWorkflowRequestsBulkDispatch200>(
      {url: `/api/v5/firms/workflow_requests/bulk_dispatch`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: postApiV5FirmsWorkflowRequestsBulkDispatchBody
    },
      );
    }
  


export const getPostApiV5FirmsWorkflowRequestsBulkDispatchMutationOptions = <TError = PostApiV5FirmsWorkflowRequestsBulkDispatchErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>, TError,{data: PostApiV5FirmsWorkflowRequestsBulkDispatchBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>, TError,{data: PostApiV5FirmsWorkflowRequestsBulkDispatchBody}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>, {data: PostApiV5FirmsWorkflowRequestsBulkDispatchBody}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5FirmsWorkflowRequestsBulkDispatch(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5FirmsWorkflowRequestsBulkDispatchMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>>
    export type PostApiV5FirmsWorkflowRequestsBulkDispatchMutationBody = PostApiV5FirmsWorkflowRequestsBulkDispatchBody
    export type PostApiV5FirmsWorkflowRequestsBulkDispatchMutationError = PostApiV5FirmsWorkflowRequestsBulkDispatchErrorType<ForbiddenErrorObject>

    /**
 * @summary Dispatch one or many requests
 */
export const usePostApiV5FirmsWorkflowRequestsBulkDispatch = <TError = PostApiV5FirmsWorkflowRequestsBulkDispatchErrorType<ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>, TError,{data: PostApiV5FirmsWorkflowRequestsBulkDispatchBody}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5FirmsWorkflowRequestsBulkDispatch>>,
        TError,
        {data: PostApiV5FirmsWorkflowRequestsBulkDispatchBody},
        TContext
      > => {

      const mutationOptions = getPostApiV5FirmsWorkflowRequestsBulkDispatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    