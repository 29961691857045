import { RequestsDataGridStyled } from 'components/Requests/components/Styles/Styles';
import {
  WfRequestSortOption,
  WorkflowRequestStatus,
  WorkflowRequestsQueryQuery,
} from 'gql/graphql';
import { UseQueryResult } from '@tanstack/react-query';
import {
  GridColDef,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom-latest';
import { REQUESTS_PAGE_ROUTES } from 'components/Requests/requests.constants';
import { useEffect, useState } from 'react';
import {
  FilterKey,
  useRequestsState,
} from 'components/Requests/state/request-state';
import { useGetWorkflowCounts } from 'components/Requests/hooks/get-workflow-counts';
import { DraftRequestOptionsMenu } from 'components/Requests/components/RequestOptionsMenu/DraftRequestOptionsMenu';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useDraftsBulkSend } from 'components/Requests/state/drafts-bulk-send-state';
import { NoResultsOverlay } from './Components/NoResultsOverlay';
import { LoadingOverlay } from './Components/LoadingOverlay';
import { TableFilters } from './Components/TableFilters/TableFilters';
import {
  AssignedToCell,
  DueDateCell,
  RequestTitleCell,
  UpdatedAtCell,
} from './Components/TableCells';
import { TableFooterContainer } from './Components/TableFooter/TableFooterContainer';
import { CustomPagination } from './Components/CustomPagination';
import { DraftsBulkSendFooter } from './Components/TableFooter/DraftsBulkSendFooter';

type DraftRequestsTableProps = {
  workflowRequestsQuery: UseQueryResult<WorkflowRequestsQueryQuery>;
  paginationModel: GridPaginationModel;
  setPaginationModel: (model: GridPaginationModel) => void;
  requestKey: FilterKey;
};

const columns: GridColDef[] = [
  {
    field: 'title',
    headerName: 'Name',
    flex: 1.5,
    sortable: false,
    renderCell: (params) => <RequestTitleCell params={params} labelIsType />,
  },
  {
    field: '_assignedContact',
    headerName: 'Assigned To',
    flex: 1,
    sortable: false,
    renderCell: (params) => <AssignedToCell params={params} />,
  },
  {
    field: '_assignedAccount',
    headerName: 'Account',
    flex: 1,
    sortable: false,
    valueGetter: (params) => `${params.row.assignedAccount?.name || ''}`,
  },
  {
    field: '_owner',
    headerName: 'Owner',
    flex: 1,
    sortable: false,
    valueGetter: (params) =>
      params.row?.owner?.employee &&
      `${params.row.owner?.employee.firstName} ${params.row.owner?.employee.lastName}`,
  },
  {
    field: 'dueDate',
    headerName: 'Due Date',
    flex: 1,
    sortable: false,
    renderCell: (params) => <DueDateCell params={params} />,
  },
  {
    field: '_lastActivity',
    headerName: 'Last Activity',
    flex: 1,
    sortable: true,
    valueGetter: (params) => params.row?.updatedAt || params.row?.createdAt,
    renderCell: (params) => <UpdatedAtCell params={params} />,
  },
  {
    field: '_menuOptions',
    headerName: ' ',
    width: 100,
    sortable: false,
    renderCell: ({ row }) => (
      <DraftRequestOptionsMenu id={row.id} type="request" />
    ),
  },
];

export const DraftRequestsTable = ({
  workflowRequestsQuery,
  paginationModel,
  setPaginationModel,
  requestKey,
}: DraftRequestsTableProps) => {
  // ld flag for draft requests checkboxes
  const { bulkSendDrafts } = useFlags();
  const { selectedDraftIds, setSelectedDraftIds } = useDraftsBulkSend();

  const { requestsCounts, isLoadingOrError } = useGetWorkflowCounts();
  const { setSortStatus } = useRequestsState();

  const [totalCount, setTotalCount] = useState(-1);

  const navigate = useNavigate();

  const workflowRequestsRows =
    workflowRequestsQuery?.data &&
    workflowRequestsQuery?.data?.workflowRequests?.collection;

  useEffect(() => {
    const requestCount = requestsCounts[WorkflowRequestStatus.Draft];
    if (isLoadingOrError || !requestCount) {
      return;
    }
    setTotalCount(requestCount);
  }, [isLoadingOrError, requestsCounts]);

  useEffect(() => {
    if (workflowRequestsQuery.isLoading || workflowRequestsQuery?.isFetching) {
      return;
    }

    setTotalCount(
      workflowRequestsQuery?.data?.workflowRequests?.totalCount || 0,
    );
  }, [
    workflowRequestsQuery?.data?.workflowRequests?.totalCount,
    workflowRequestsQuery?.isFetching,
    workflowRequestsQuery.isLoading,
  ]);

  const isLoading =
    workflowRequestsQuery?.isLoading || workflowRequestsQuery?.isFetching;

  const refetchAll = () => {
    workflowRequestsQuery.refetch();
  };

  // wait for both queries to finish before populating the table row array
  // avoids a partial render of the table data
  const tableRows = workflowRequestsRows && [...workflowRequestsRows];

  const handleRowClick = (row: any) => {
    navigate(`${REQUESTS_PAGE_ROUTES.dispatch}/${row.id}`);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length === 0 || !model[0].sort) {
      return;
    }
    const sortField =
      model[0].field === 'dueDate'
        ? WfRequestSortOption.DueDate
        : WfRequestSortOption.LastActivity;
    setSortStatus(WorkflowRequestStatus.Draft, {
      sortField,
      sortDirection: model[0].sort,
    });
  };

  if (totalCount === -1) {
    return <LoadingOverlay />;
  }

  const rowSelectionCallback = (drafts: GridRowSelectionModel) => {
    if (bulkSendDrafts) {
      setSelectedDraftIds(drafts as string[]);
    }
  };

  const getSlots = {
    loadingOverlay: LoadingOverlay,
    noResultsOverlay: () =>
      NoResultsOverlay({
        message: 'No Draft Requests',
        refetch: refetchAll,
      }),
    noRowsOverlay: () =>
      NoResultsOverlay({
        message: 'No Draft Requests',
        refetch: refetchAll,
      }),
    pagination: CustomPagination,
    // footer: bulkSendDrafts ? TableFooterContainer: undefined,
  };
  // this needs to be done like this for jest to work
  // put back in getSlots object once the flag is removed
  if (bulkSendDrafts) {
    // @ts-ignore
    getSlots.footer = TableFooterContainer;
  }

  return (
    <>
      <TableFilters query={workflowRequestsQuery} requestKey={requestKey} />
      <RequestsDataGridStyled
        hasFilters
        initialState={{
          sorting: {
            sortModel: [{ field: '_lastActivity', sort: 'asc' }],
          },
          pagination: {
            paginationModel,
          },
        }}
        rows={tableRows || []}
        columns={columns}
        sortingMode="server"
        loading={isLoading}
        onRowClick={(params) => {
          handleRowClick(params.row);
        }}
        slots={getSlots}
        slotProps={{
          footer: {
            showPagination: true,
            component: <DraftsBulkSendFooter />,
          },
        }}
        disableColumnMenu
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        rowCount={totalCount}
        rowHeight={72}
        columnHeaderHeight={36}
        pageSizeOptions={[5, 25, 50]}
        onSortModelChange={handleSortModelChange}
        checkboxSelection={bulkSendDrafts}
        isRowSelectable={(params) =>
          // this will only work for the bulk send action as "TaxDeliveryWorkflow"s
          // are not allowed to be bulk sent but may allow other actions in the future
          // think "bulk delete" or "bulk assign"
          (params.row.workflow.type === 'OrganizerWorkflow' ||
            params.row.workflow.type === 'DefaultWorkflow') &&
          params.row.assignedContacts.length > 0
        }
        rowSelectionModel={selectedDraftIds}
        onRowSelectionModelChange={rowSelectionCallback}
        keepNonExistentRowsSelected // keep selected rows in state when pagination changes
      />
    </>
  );
};
