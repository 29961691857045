import axios from 'axios';
import {
  WorkflowRPARequestPayload,
  WorkflowRPARequestResponse,
} from 'components/Requests/requests.types';
import {
  WORKFLOWS_RPA_REQUESTS,
  WORKFLOW_ZIP_PACKAGE,
} from 'constants/constants';
import { graphql } from 'gql';
import { CamelToSnakeCase } from './utils/CamelToSnakeCase';

export async function postWorkflowRPARequest(
  payload: WorkflowRPARequestPayload,
) {
  const { data } = await axios.post<WorkflowRPARequestResponse>(
    WORKFLOWS_RPA_REQUESTS,
    CamelToSnakeCase(payload),
  );
  return data;
}

export const deleteWorkflowRequestDocument = graphql(/* GraphQL */ `
  mutation deleteWorkflowRequest($workflowRequestId: Uuid!) {
    deleteWorkflowRequest(workflowRequestId: $workflowRequestId) {
      errors {
        message
      }
    }
  }
`);

export const RpaDocumentFragment = graphql(/* GraphQL */ `
  fragment RpaDocument on RpaDocument {
    id
    fileUrl
    legacyId
    title
  }
`);

export const rpaDocumentProcessingCountQueryDocument = graphql(/* GraphQL */ `
  query rpaDocumentProcessingCount($status: RpaDocumentStatus) {
    rpaDocuments(status: $status) {
      totalCount
    }
  }
`);

export const WorkflowRequestSectionFragment = graphql(/* GraphQL */ `
  fragment WorkflowRequestSection on WorkflowRequestSection {
    id
    title
    requestItems {
      ...WorkflowRequestItem
    }
  }
`);

export const WorkflowRequestFragment = graphql(/* GraphQL */ `
  fragment WorkflowRequest on WorkflowRequest {
    id
    title
    assignedContacts {
      id
      firstName
      middleName
      lastName
      legacyId
      smsNumber
      smsOptIn
      cpaUser {
        id
      }
    }
    assignedEmployees {
      id
      firstName
      middleName
      lastName
      legacyId
    }
    owner {
      contact {
        firstName
        lastName
      }
      employee {
        firstName
        lastName
      }
    }
    updatedAt
    dueDate
    status
    percentComplete
    updatedAt
    assignedAccount {
      id
      legacyId
      name
    }
    workflow {
      title
    }
    requestSections {
      ...WorkflowRequestSection
    }
    rpaDocuments {
      ...RpaDocument
    }
    workflow {
      ...WorkflowWithSections
    }
  }
`);

export const WorkflowRequestsWorkflowRequestFragment = graphql(/* GraphQL */ `
  fragment WorkflowRequestsWorkflowRequest on WorkflowRequest {
    id
    title
    assignedAccount {
      id
      name
    }
    assignedContacts {
      id
      firstName
      middleName
      lastName
      legacyId
      smsNumber
      smsOptIn
      cpaUser {
        id
      }
    }
    assignedEmployees {
      id
      firstName
      middleName
      lastName
      legacyId
      cpaUser {
        id
      }
    }
    owner {
      employee {
        firstName
        lastName
      }
      contact {
        firstName
        lastName
      }
    }
    updatedAt
    dueDate
    status
    percentComplete
    updatedAt
    workflow {
      title
      type
    }
  }
`);

export const WorkflowRequestDependencyDocument = graphql(/* GraphQL */ `
  fragment WorkflowRequestDependencyDocument on Document {
    __typename
    id
    uuid
    legacyId
    awsUrlFileName
  }
`);

export const WorkflowRequestDependencyDynamic = graphql(/* GraphQL */ `
  fragment WorkflowRequestDependencyDynamic on DynamicDependency {
    __typename
    uuid: id
    readUrl
    type
  }
`);

export const WorkflowRequestItemFragment = graphql(/* GraphQL */ `
  fragment WorkflowRequestItem on WorkflowRequestItem {
    responseItem {
      __typename
      ...WorkflowResponseItem
    }
    prompt
    dependency {
      ...WorkflowRequestDependencyDynamic
      ... on Invoice {
        __typename
        legacyId
      }
      ...WorkflowRequestDependencyDocument
      ... on Payable {
        __typename
        payableId: id
        document {
          id
          uuid
          legacyId
        }
        url
      }
    }
    id
  }
`);

export const workflowRequestQueryDocument = graphql(/* GraphQL */ `
  query workflowRequestQuery($id: Uuid!) {
    workflowRequest(id: $id) {
      ...WorkflowRequest
    }
  }
`);

export const workflowRequestsQueryDocument = graphql(/* GraphQL */ `
  query workflowRequestsQuery(
    $status: WorkflowRequestStatus
    $statuses: [WorkflowRequestStatus!]
    $filter: String
    $filterData: WfRequestsFilterInput
    $page: Int
    $perPage: Int
    $sortField: WfRequestSortOption
    $sortDirection: String
  ) {
    workflowRequests(
      status: $status
      statuses: $statuses
      filter: $filter
      filterData: $filterData
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortDirection: $sortDirection
    ) {
      totalCount
      collection {
        ...WorkflowRequestsWorkflowRequest
      }
    }
  }
`);

export const workflowRequestsCountQueryDocument = graphql(/* GraphQL */ `
  query workflowRequestsCount(
    $status: WorkflowRequestStatus
    $statuses: [WorkflowRequestStatus!]
    $filterData: WfRequestsFilterInput
  ) {
    workflowRequests(
      status: $status
      statuses: $statuses
      filterData: $filterData
    ) {
      totalCount
    }
  }
`);

export const createWorkflowRequestMutationDocument = graphql(/* GraphQL */ `
  mutation createWorkflowRequest($workflowId: Uuid!, $title: String) {
    createWorkflowRequest(workflowId: $workflowId, title: $title) {
      errors {
        message
      }
      workflowRequest {
        title
        id
      }
    }
  }
`);

export const updateWorkflowRequestMutationDocument = graphql(/* GraphQL */ `
  mutation updateWorkflowRequest(
    $workflowRequestId: Uuid!
    $legacyAssignedAccountId: ID
    $assignedContactIds: [Uuid!]
    $assignedAccountId: Uuid
    $dueDate: ISO8601DateTime
    $title: String
    $status: WorkflowRequestStatus
  ) {
    updateWorkflowRequest(
      legacyAssignedAccountId: $legacyAssignedAccountId
      workflowRequestId: $workflowRequestId
      assignedContactIds: $assignedContactIds
      assignedAccountId: $assignedAccountId
      dueDate: $dueDate
      title: $title
      status: $status
    ) {
      errors {
        message
      }
      workflowRequest {
        title
        id
        dueDate
        assignedContacts {
          id
        }
      }
    }
  }
`);

export const assignEmployeeWorkflowRequestMutationDocument =
  graphql(/* GraphQL */ `
    mutation assignEmployeeWorkflowRequest(
      $workflowRequestId: Uuid!
      $assignedEmployeeIds: [ID!]
    ) {
      assignEmployeeWorkflowRequest(
        workflowRequestId: $workflowRequestId
        assignedEmployeeIds: $assignedEmployeeIds
      ) {
        errors {
          message
        }
        workflowRequest {
          title
          id
          dueDate
          assignedContacts {
            id
          }
        }
      }
    }
  `);

export const dispatchWorkflowRequestDocument = graphql(/* GraphQL */ `
  mutation dispatchWorkflowRequest($workflowRequestId: Uuid!) {
    dispatchWorkflowRequest(workflowRequestId: $workflowRequestId) {
      errors {
        message
      }
      workflowRequest {
        id
      }
    }
  }
`);

export const bulkSendRequests = graphql(/* GraphQL */ `
  mutation bulkSendRequests(
    $workflowId: Uuid!
    $recipientList: [WfRequestRecipientInput!]!
    $title: String
    $dueDate: ISO8601DateTime
  ) {
    bulkSendRequests(
      workflowId: $workflowId
      recipientList: $recipientList
      title: $title
      dueDate: $dueDate
    ) {
      errors {
        message
      }
      workflowRequests {
        id
      }
    }
  }
`);

export function getZipPackage(id: string) {
  return axios.post(WORKFLOW_ZIP_PACKAGE(id));
}
