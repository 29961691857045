/* eslint-disable global-require */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormContext } from 'react-hook-form';
import { FormAlert } from 'components/Login/LoginV5/components/FormAlert/FormAlert';
import { useState } from 'react';
import { Link } from '@mui/material';
import { LostAccessDialog } from './components';

export interface MFAFormProps {
  backToLogin?: () => void;
}

export const MFAForm = ({ backToLogin }: MFAFormProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<{ two_factor_code: string }>();
  const [showNeedHelpModal, setShowNeedHelpModal] = useState(false);

  const twoFactorCodeError = errors?.two_factor_code?.message;

  return (
    <>
      <h1>Enter Passcode</h1>
      <p className="text-center mb-3">
        Enter the code on your mobile passcode <br /> application
      </p>
      <FormAlert />
      <div>
        <div className={`form-group ${twoFactorCodeError && 'form-error'}`}>
          <input
            id="two_factor_code"
            type="text"
            inputMode="numeric"
            pattern="[0-9]{6}"
            className="form-control"
            autoComplete="new-password"
            placeholder="Enter Passcode"
            data-testid="two_factor_code"
            {...register('two_factor_code')}
          />

          <div className="text-danger error_label" id="mail">
            {twoFactorCodeError}
          </div>
        </div>
        <div className="clearfix" />
        <div className="form-group">
          <button
            type="submit"
            id="passcodeSubmit"
            name="passcode"
            className="btn btn-primary w-100"
          >
            Verify Code
          </button>
          <div className="crPage--bott text-center mt-3">
            <a
              href="#"
              onClick={() => setShowNeedHelpModal(true)}
              className="btn tptBtn"
            >
              <b className="text-primary">Need help logging in?</b>
            </a>
          </div>
          {backToLogin && (
            <div className="crPage--bott text-center mt-3">
              <span className="txtInfo">Back to </span>
              <Link href="#" onClick={backToLogin} className="btn tptBtn">
                <b>Login</b>
              </Link>
            </div>
          )}
        </div>
      </div>
      <LostAccessDialog
        open={showNeedHelpModal}
        handleClose={() => setShowNeedHelpModal(false)}
      />
    </>
  );
};

export default MFAForm;
