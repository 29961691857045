import { useEffect, useState } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import Axios from 'axios';
import { SEARCH_ACCOUNTS_WITH_FILTERS, SERVICE_ALIASES } from 'constants/constants';
import Select from 'react-select';
import { debounce } from 'lodash';
import { SelectOption, ModalProps } from './types';
import { selectStyles } from '../../../common/CustomFields';
import { AlertMessage } from '../../../utilities/utils';

export default function EditLinkingModal ({ closeModal, serviceAlias, serviceAliasData, setServiceAliasData }: ModalProps) {
  const [accounts, setAccounts] = useState([]);
  const [accountQuery, setAccountQuery] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState<SelectOption | null>(
    serviceAlias.aliasable_id ? { value: serviceAlias.aliasable_id, label: serviceAlias.aliasable?.name } : null
  );

  const updateserviceAliasData = () => {
    const updatedServiceAliases = serviceAliasData.map(alias =>
      alias.id === serviceAlias.id ?
        { ...alias, aliasable_id: selectedAccount!.value, aliasable: { name: selectedAccount!.label } }
        : alias
    )
    setServiceAliasData(updatedServiceAliases)
  }

  const saveLink = () => {
    const request = Axios.put(`${SERVICE_ALIASES}/${serviceAlias.id}`, { service_alias: { aliasable_id: selectedAccount!.value } })
    request
      .then(response => {
        AlertMessage('success', response, 3000);
        updateserviceAliasData()
        closeModal()
      })
      .catch(error => {
        AlertMessage('error', error.message, 3000);
      })
  }

  const fetchAccounts = () => {
    Axios.post(SEARCH_ACCOUNTS_WITH_FILTERS, { keyword: accountQuery })
      .then((res) => {
        setAccounts(res.data.data.map((account: Account) => (
          { label: account.name, value: account.id }
        )))
      })
  }

  useEffect(() => {
    fetchAccounts();
  }, [accountQuery]);

  return (
    <Modal
      isOpen
      className='customModal customModal--center customModal--alert customModal--w500 customModal--nopadding'
    >
      <div className="ModalHeader">
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={closeModal}
        >
          <i className="icon-close2" aria-hidden="true" />
        </button>
      </div>
      <ModalBody className="modalContent" style={{ overflow: 'visible' }}>
        <div className="modalContent--inner">
          <div className="modalContent--header text-large">
            <h5 className="modalContent--header__heading mb-3">
              Edit Account Link
            </h5>
          </div>
          <div className="mb-4 d-flex align-items-center">
            <span className="input-label">SmartVault Account:</span>
            <span className="select-wrapper ml-2 smart-vault-account-name">
              {serviceAlias.external_name}
            </span>
          </div>
          <div className="d-flex align-items-center">
            <span className="input-label">Liscio Account:</span>
            <div className="select-wrapper ml-2">
              <Select
                placeholder="Search Liscio Accounts"
                className="AccountSelect"
                styles={selectStyles}
                options={accounts}
                onInputChange={debounce((e) => {setAccountQuery(e)}, 600)}
                onChange={(e: any) => setSelectedAccount(e)}
                value={selectedAccount}
                isSearchable
                isClearable
              />
          </div>
          </div>
          <div className="row">
            <div className="col-12 justify-content-end formbtn justify-content-end">
              <div className="btn-wrap btn--leftspace">
                <Button className="btn btn-outline-light" onClick={closeModal}>
                  Cancel
                </Button>
                <Button className="btn btn-primary" onClick={saveLink}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
