import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const customV5Instance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = window
    .AXIOS_V5_INSTANCE({
      ...config,
      cancelToken: source.token,
    })
    .then(({ data }: AxiosResponse) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;
export default customV5Instance;
