import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { Link } from 'react-router-dom-latest';
import { PROFILE_SETTINGS_MENU, THIRD_PARTY_APPS } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SmartVaultWidget from 'common/SmartVault/widget';
import CustomImage from 'common/CustomImage';
import styled from '@emotion/styled';
import SharePointPicker from 'common/SharePoint/Picker';
import GooglePicker from '../../common/GooglePicker';
import { AlertMessage } from '../../utilities/utils';

type ExportModalProps = {
  selectedRecords: { id: string}[];
  closeModal: () => void;
}

type ThirdPartyApps = {
  id: string;
  name: string;
  access_token: string;
}[];

export default function ExportModal ({ selectedRecords, closeModal }: ExportModalProps) {
  const supportedApps = ['smartvault', 'googledrive', 'sharepoint', 'onedrive']

  const defaultMessage = (
    <>
      No destinations configured! Please see our
      <Link to={`${PROFILE_SETTINGS_MENU}?tab=third_party_apps`}>Third Party Integrations</Link>
       page to browse all available connections.
    </>
  )

  const [thirdPartyApps, setThirdPartyApps] = useState<ThirdPartyApps>([]);
  const [selectedModal, setSelectedModal] = useState('');
  const [showDefaultMessage, setShowDefaultMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleModalClose = () => closeModal()

  useEffect(() => {
    fetchThirdPartyApps();
  }, []);

  const fetchThirdPartyApps = () => {
    const request = axios.get(THIRD_PARTY_APPS);
    request
      .then(response => {
        const apps = response.data.data.filter((app: any) => supportedApps.includes(app.id) && app.token_exits)
        if (apps.length) {
          setThirdPartyApps(apps);

          if (apps.length === 1) {
            setSelectedModal(apps[0].id);
          }
        } else {
          setShowDefaultMessage(true);
        }
      })
      .catch(error => {
        AlertMessage('error', error.message, 3000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const loading = (
    <div style={{ height: '200px' }}>
      <div id="loading" className="d-block"/>
    </div>
  )

  const IntegrationOption = styled.div`
    border-radius: .25em;
    &:hover {
      cursor: pointer;
      background-color: #f2f2f2 !important;
    }`

  const modalBody = (
    <>
      <div className="modalContent--inner pb-0">
        {showDefaultMessage && defaultMessage}
        {thirdPartyApps.map((app) => (
          <IntegrationOption className="p-2" key={app.id} onClick={() => setSelectedModal(app.id)}>
            <CustomImage
              alt="logo"
              className="mr-2 mb-1"
              src={`${app.id}.png`}
              style={{ width: '24px', height: '24px' }}
            />
            <span className="pointer text-small">{app.name}</span>
          </IntegrationOption>
        ))}
      </div>
      <div className="row">
        <div className="col-12 justify-content-end formbtn mb-4">
          <Button
            className="btn btn-outline-light mr-4"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  )

  const pickerModal = (
    <Modal
      isOpen
      className="customModal customModal--center customModal--alert customModal--w600 customModal--nopadding"
    >
      <ModalHeader className="ModalHeader">
        <button
          type="button"
          onClick={handleModalClose}
          className="close"
          aria-label="Close"
        >
          <i className="icon-close2" />
        </button>
      </ModalHeader>
      <ModalHeader className="modalHeader">
        <div className="text-medium pl-2">
          <div>Select Destination</div>
        </div>
      </ModalHeader>
      <ModalBody className="modalContent">
        {isLoading ? loading : modalBody}
      </ModalBody>
    </Modal>
  )

  const smartVaultAccessToken =
    thirdPartyApps.find(app => app.id === 'smartvault')?.access_token

  return(
    <>
      { selectedModal === '' && pickerModal }
      { selectedModal === 'smartvault' &&
        <SmartVaultWidget
          mode='export'
          token={smartVaultAccessToken}
          selectedRecords={selectedRecords}
          cancelWidget={closeModal}
        />
      }
      { selectedModal === 'googledrive' &&
        <GooglePicker
          mode='export'
          selectedRecords={selectedRecords}
          cancelWidget={closeModal}
        />
      }
      { (selectedModal === 'sharepoint' || selectedModal === 'onedrive') &&
        <SharePointPicker
          mode='export'
          appKey={selectedModal}
          selectedRecords={selectedRecords}
          closeModal={closeModal}
          cancelWidget={setSelectedModal}
        />
      }
    </>
  )
}

