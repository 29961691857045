import {
  Box,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import { WorkflowItemType } from 'components/Requests/requests.types';
import {
  useCreateWorkflowItemCondition2,
  useDeleteWorkflowItem,
  useCreateWorkflowItem2,
  useDeleteWorkflowItemCondition,
} from 'hooks/workflow-item-hooks';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  WorkflowItemCondition2Fragment,
  WorkflowItemFragment,
} from 'gql/graphql';
import { AlertMessage, getFeatureGates } from 'utilities/utils';
import { ConditionalQuestionEditor } from 'components/Requests/components/RequestsBuilder/components/ConditionalQuestionEditor/ConditionalQuestionEditor';
import { AddCircle } from '@mui/icons-material';
import { TOAST_FAILURE } from 'constants/constants';
import { TooltipStyled } from 'ui/TooltipStyled/TooltipStyled';
import { useRequestBuilderContext } from 'components/Requests/components/RequestsBuilder/context/RequestsBuilderContext';
import {
  getItem2Conditional,
  getWorkflowItemWithIdFromCache,
} from 'components/Requests/components/RequestsBuilder/utils/item-utils';
import {
  addWorkflowItemToCache,
  deleteWorkflowItemFromCache,
} from 'components/Requests/components/RequestsBuilder/utils/mutation-utils';
import { workflowForConditionIdQueryDocument } from 'api/workflow-items';
import { useGraphQL } from 'hooks/useGraphQL';
import { useGetWorkflowById } from 'hooks/workflow-hooks';

export interface ConditionsEditorProps {
  activeUUID: string;
  number: number;
}

export function ConditionsEditor({
  number,
  activeUUID,
}: ConditionsEditorProps) {
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { workflowId, isErrored, isSubmitting } = useRequestBuilderContext();

  const { data: workflowData } = useGraphQL(
    workflowForConditionIdQueryDocument,
    {
      id: workflowId,
    },
  );

  const { mutate: createWorkflowItemMutation, isLoading } =
    useCreateWorkflowItem2();
  const { mutate: deleteWorkflowItemMutation } = useDeleteWorkflowItem();
  const { mutate: createCondition } = useCreateWorkflowItemCondition2();
  const { mutate: deleteWorkflowItemConditionMutation } =
    useDeleteWorkflowItemCondition();
  const [conditionalItems, setConditionalItems] = useState<
    WorkflowItemFragment[]
  >([]);
  const [parentItem, setParentItem] = useState<WorkflowItemFragment | null>(
    null,
  );

  // the new model has a single conditional item that we need the id of to create the items
  const [workflowItemConditionalTemp, setWorkflowItemConditionalTemp] =
    useState<WorkflowItemCondition2Fragment | null>(null);

  // we do this to listen to changes in the cache without hitting the backend
  const { data: workflowData2 } = useGetWorkflowById(workflowId, {
    staleTime: Infinity,
  });
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  // the condition is stored on the conditional item
  const [enabled, setEnabled] = useState(false);

  const [matchValue, setMatchValue] = useState<string>('false');

  const workflowItemConditional =
    getItem2Conditional(workflowData, activeUUID) ??
    workflowItemConditionalTemp;

  useEffect(() => {
    const [prntItem, condItems] = getWorkflowItemWithIdFromCache(
      queryClient,
      activeUUID,
      workflowId || '',
    );
    setParentItem(prntItem);

    // on first load, if there are no conditional items, enable the editor
    if (initialLoad && condItems.length > 0) {
      setEnabled(true);
      setInitialLoad(false);
    }

    setConditionalItems(condItems);
    if (matchValue !== condItems[0]?.conditions?.[0]?.matchValue) {
      setMatchValue(
        condItems[0]?.conditions?.[0]?.matchValue !== 'false'
          ? 'true'
          : 'false',
      );
    }
  }, [
    queryClient,
    workflowId,
    workflowData2,
    matchValue,
    activeUUID,
    initialLoad,
  ]);

  const createInitialWorkflowItemAndCondition = useCallback(() => {
    if (!parentItem) {
      AlertMessage(TOAST_FAILURE, 'Failed to add condition');
      return;
    }
    createCondition(
      {
        workflowItemId: parentItem.id,
        matchValue,
        conditionType: 'equals',
      },
      {
        onSettled: (conditionResponse) => {
          const worklowItemCondition =
            conditionResponse?.createWorkflowItemCondition2
              ?.workflowItemCondition;
          if (!worklowItemCondition) {
            // we don't want a nonfunctional conditional item hanging around
            // deleteWorkflowItem(responseItem?.id);
          }
          function addCondition() {
            createWorkflowItemMutation(
              {
                conditionId:
                  conditionResponse?.createWorkflowItemCondition2
                    ?.workflowItemCondition?.id,
                prompt: '',
                workflowItemType: WorkflowItemType.Boolean,
                workflowSectionId: parentItem?.section.id,
              },
              {
                onSuccess: (response) => {
                  let item: WorkflowItemFragment = {
                    ...response?.createWorkflowItem2?.workflowItem,
                    conditionalParentId: parentItem?.id,
                    conditions: [
                      conditionResponse?.createWorkflowItemCondition2
                        ?.workflowItemCondition as WorkflowItemCondition2Fragment,
                    ],
                  } as WorkflowItemFragment;

                  setWorkflowItemConditionalTemp(
                    conditionResponse?.createWorkflowItemCondition2
                      ?.workflowItemCondition as WorkflowItemCondition2Fragment,
                  );

                  // a very silly hack to handle the empty prompt that comes back from the server if we send them blank
                  if (item.prompt === 'Empty Prompt') {
                    item = {
                      prompt: '',
                      ...item,
                    };
                  }
                  addWorkflowItemToCache(queryClient, item, workflowId);
                },
              },
            );
          }
          // await item added to react query cache
          setTimeout(addCondition, 0);
        },
      },
    );
  }, [
    createCondition,
    createWorkflowItemMutation,
    matchValue,
    parentItem,
    queryClient,
    workflowId,
  ]);

  const createConditionalItem = useCallback(() => {
    if (!parentItem) {
      AlertMessage(TOAST_FAILURE, 'Failed to add condition');
      return;
    }
    if (!workflowItemConditional) {
      return;
    }

    createWorkflowItemMutation(
      {
        conditionId: workflowItemConditional.id,
        prompt: '',
        workflowItemType: WorkflowItemType.Boolean,
        workflowSectionId: parentItem?.section.id,
      },
      {
        onSuccess: (response) => {
          let item: WorkflowItemFragment = {
            ...response?.createWorkflowItem2?.workflowItem,
            conditionalParentId: parentItem?.id,
            conditions: [workflowItemConditional],
          } as WorkflowItemFragment;

          // a very silly hack to handle the empty prompt that comes back from the server if we send them blank
          if (item.prompt === 'Empty Prompt') {
            item = {
              prompt: '',
              ...item,
            };
          }
          addWorkflowItemToCache(queryClient, item, workflowId);
        },
      },
    );
  }, [
    workflowItemConditional,
    createWorkflowItemMutation,
    parentItem,
    queryClient,
    workflowId,
  ]);

  const deleteWorkflowItem = useCallback(
    (workflowItemId: string, keepParent: boolean = false) => {
      // Remove from the cache
      deleteWorkflowItemFromCache(queryClient, { workflowItemId }, workflowId);

      deleteWorkflowItemMutation(
        {
          workflowItemId,
        },
        {
          onSuccess: (response) => {
            if (response?.deleteWorkflowItem?.errors?.length) {
              AlertMessage(TOAST_FAILURE, 'Failed to delete conditions');
              return;
            }
            if (!keepParent) setParentItem(null);
          },
        },
      );
    },
    [deleteWorkflowItemMutation, queryClient, workflowId],
  );

  useEffect(() => {
    if (enabled && parentItem && conditionalItems.length === 0) {
      createInitialWorkflowItemAndCondition();
    }
  }, [
    parentItem,
    conditionalItems.length,
    createInitialWorkflowItemAndCondition,
    workflowId,
    enabled,
    deleteWorkflowItem,
  ]);

  const handleEnabledChange = () => {
    if (enabled === true && conditionalItems.length > 0) {
      // delete the new condition
      deleteWorkflowItemConditionMutation({
        workflowItemConditionId: workflowItemConditional?.id,
      });
      setConditionalItems([]);
    }

    setEnabled((cEnabled) => !cEnabled);
  };

  const featureGate = getFeatureGates();
  const hasConditionalQuestionsFeature = featureGate?.conditional_questions;
  const conditionalFeatureTooltip =
    !hasConditionalQuestionsFeature &&
    'Upgrade to Pro Tier to create conditional questions.';

  return (
    <Stack gap="20px" paddingTop="20px">
      <Box>
        <TooltipStyled title={conditionalFeatureTooltip}>
          <FormControlLabel
            control={
              <Switch
                checked={enabled}
                onChange={handleEnabledChange}
                disabled={
                  !hasConditionalQuestionsFeature || !parentItem || isErrored
                }
              />
            }
            label="Conditions"
          />
        </TooltipStyled>
        <Typography color="text.secondary">
          Add conditions depending on the client answer.
        </Typography>
      </Box>
      {enabled && (
        <Stack bgcolor="common.white" gap="inherit" padding="20px">
          <Stack direction="row">
            <Stack
              justifyContent="center"
              alignItems="center"
              bgcolor={theme.palette.success.main}
              borderRadius="4px 0px 0px 4px"
              sx={{ padding: '6px 12px' }}
            >
              <Typography
                component="label"
                htmlFor="matchValue"
                fontSize=".875rem"
                color={theme.palette.success.contrastText}
                fontWeight={700}
              >
                If
              </Typography>
            </Stack>
            <Select
              fullWidth
              value={matchValue}
              defaultValue="true"
              onChange={({ target: { value } }) => {
                setMatchValue(value);
              }}
              variant="standard"
              inputProps={{
                id: 'matchValue',
                sx: {
                  border: `2px solid ${theme.palette.success.main}`,
                  borderLeft: 'none',
                  borderRadius: '0px 4px 4px 0px',
                },
              }}
              disabled={isSubmitting || isErrored}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </Stack>
          {matchValue &&
            conditionalItems.map((ifItem, idx) => (
              <ConditionalQuestionEditor
                key={ifItem.id}
                label={`${number}.${idx + 1}`}
                item={ifItem}
                matchValue={matchValue}
              />
            ))}
          <Stack flexDirection="row" alignItems="center">
            <Divider sx={{ flexGrow: 1, bgcolor: 'primary.light' }} />
            <IconButton
              onClick={createConditionalItem}
              disabled={
                isLoading ||
                isSubmitting ||
                isErrored ||
                !hasConditionalQuestionsFeature
              }
              color="primary"
              aria-label="Add conditional question"
            >
              <AddCircle />
            </IconButton>
            <Divider sx={{ flexGrow: 1, bgcolor: 'primary.light' }} />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
