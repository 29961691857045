import { QueryClient } from '@tanstack/react-query';
import { workflowQueryDocument } from 'api/workflows';
import {
  CreateWorkflowItemMutationVariables,
  CreateWorkflowSectionMutationVariables,
  DeleteWorkflowItemMutationVariables,
  DeleteWorkflowSectionMutationVariables,
  SaveWorkflowMutationVariables,
  UpdateWorkflowMutationVariables,
  WorkflowItemFragment,
  WorkflowQueryQuery,
  WorkflowSection,
  WorkflowSectionFragment,
  WorkflowStatus,
  WorkflowItemCondition2Fragment,
  WorkflowItem2Fragment,
} from 'gql/graphql';
import { randomString } from 'utilities/utils';
import { workflowRequestsQueryDocument } from 'api/workflow-requests';
import { SectionItemTuple } from 'components/Requests/components/RequestsBuilder/components/QuestionsBuilder';
import { getIndexInCache } from './item-utils';

export function getWorkflowItemWithIndexFromCache(
  queryClient: QueryClient,
  activeIndex: SectionItemTuple,
  workflowId: string,
): [WorkflowItemFragment | null, Array<WorkflowItemFragment>] {
  const [sectionIndex, itemIndex] = activeIndex;
  if (sectionIndex === null || itemIndex === null) {
    return [null, []];
  }
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (!oldWorkflows) {
    return [null, []];
  }

  const indexActual = getIndexInCache(
    activeIndex,
    (oldWorkflows.workflow?.sections as WorkflowSection[]) || [],
  );

  if (indexActual === undefined) {
    return [null, []];
  }

  const parentItem =
    oldWorkflows?.workflow?.sections?.[sectionIndex]?.items?.[indexActual];

  if (parentItem === undefined) {
    return [null, []];
  }

  const conditionalItems =
    oldWorkflows?.workflow?.sections?.[sectionIndex]?.items?.filter(
      (item) =>
        item.conditions?.length && item.conditionalParentId === parentItem?.id,
    ) || [];
  return [parentItem, conditionalItems];
}

export async function addWorkflowItemToCacheOptimistically(
  queryClient: QueryClient,
  variables: Required<CreateWorkflowItemMutationVariables>,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: variables.workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: oldWorkflows?.workflow?.sections?.map((section) =>
          section.id === variables.workflowSectionId
            ? {
                ...section,
                items: [
                  ...(section.items || []),
                  {
                    id: randomString(),
                    prompt: variables.prompt,
                    type: variables.workflowItemType,
                    updatedAt: new Date().toISOString(),
                    section: {
                      id: variables.workflowSectionId,
                    },
                  },
                ],
              }
            : section,
        ),
      },
    });
  }
}

export async function addWorkflowItemToCache(
  queryClient: QueryClient,
  workflowItem: WorkflowItemFragment,
  workflowId: string,
  replace: boolean = false,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: oldWorkflows?.workflow?.sections?.map((section) => {
          if (section.id === workflowItem.section.id)
            return {
              ...section,
              items: replace
                ? section.items?.map((item) =>
                    item.id === workflowItem.id ? workflowItem : item,
                  )
                : [...(section.items || []), workflowItem],
            };
          return section;
        }),
      },
    });
  }
}

export async function addWorkflowItemToCache2(
  queryClient: QueryClient,
  workflowItem: WorkflowItem2Fragment,
  workflowId: string,
  replace: boolean = false,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: oldWorkflows?.workflow?.sections?.map((section) => {
          if (section.id === workflowItem.section.id)
            return {
              ...section,
              items: replace
                ? section.items?.map((item) =>
                    item.id === workflowItem.id ? workflowItem : item,
                  )
                : [...(section.items || []), workflowItem],
            } as WorkflowSection;
          return section;
        }),
      },
    });
  }
}

export async function addWorkflowConditionToCache(
  queryClient: QueryClient,
  workflowItemId: string,
  workflowCondition: Omit<WorkflowItemCondition2Fragment, '__typename'>,
  workflowId: string,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    let itemIndex: number | undefined = -1;
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: oldWorkflows?.workflow?.sections?.map((section) => {
          if (itemIndex !== undefined && itemIndex !== -1) return section;
          itemIndex = section.items?.findIndex(
            (item) => item.id === workflowItemId,
          );

          return {
            ...section,
            items:
              itemIndex === undefined || itemIndex === -1
                ? section.items
                : ([
                    ...(section.items?.slice(0, itemIndex) || []),
                    {
                      ...section.items?.[itemIndex],
                      conditions: [workflowCondition],
                    },
                    ...(section.items?.slice(itemIndex + 1) || []),
                  ] as WorkflowItemFragment[]),
          };
        }),
      },
    });
  }
}

export async function updateWorkflowConditionInCache(
  queryClient: QueryClient,
  workflowCondition: Omit<WorkflowItemCondition2Fragment, '__typename'>,
  workflowId: string,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    let conditionFound = -1;
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: oldWorkflows?.workflow?.sections?.map((section) => {
          if (conditionFound !== -1) return section;

          return {
            ...section,
            items:
              conditionFound === undefined || conditionFound === -1
                ? section.items?.map((item) => {
                    conditionFound =
                      item.conditions?.findIndex(
                        (condition) => condition.id === workflowCondition.id,
                      ) ?? -1;
                    return {
                      ...item,
                      conditions:
                        conditionFound !== -1
                          ? [workflowCondition]
                          : item.conditions,
                    };
                  })
                : section.items,
          };
        }),
      },
    });
  }
}

export async function addWorkflowSectionToCache(
  queryClient: QueryClient,
  workflowSection: WorkflowSectionFragment,
  workflowId: string,
  replace: boolean = false,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: replace
          ? oldWorkflows.workflow?.sections?.map((section) => {
              if (section.id === workflowSection.id) {
                return workflowSection;
              }
              return section;
            })
          : [...(oldWorkflows?.workflow?.sections || []), workflowSection],
      },
    });
  }
}

export async function addWorkflowSectionsToCache(
  queryClient: QueryClient,
  workflowSections: WorkflowSectionFragment[],
  workflowId: string,
  replace: boolean = false,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: replace
          ? workflowSections
          : [...(oldWorkflows?.workflow?.sections || []), ...workflowSections],
      },
    });
  }
}

export async function addWorkflowSectionToCacheOptimistically(
  queryClient: QueryClient,
  variables: CreateWorkflowSectionMutationVariables,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: variables.workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: [
          ...(oldWorkflows?.workflow?.sections || []),
          {
            id: randomString(),
            title: variables.title,
            items: [],
            updatedAt: new Date().toISOString(),
          },
        ],
      },
    });
  }
}

export async function deleteWorkflowSectionFromCache(
  queryClient: QueryClient,
  variables: DeleteWorkflowSectionMutationVariables,
  workflowId: string,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);

  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: oldWorkflows?.workflow?.sections?.filter(
          (section) => section.id !== variables.workflowSectionId,
        ),
      },
    });
  }
}

export async function deleteWorkflowItemFromCache(
  queryClient: QueryClient,
  variables: DeleteWorkflowItemMutationVariables,
  workflowId: string,
) {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: workflowId },
  ];
  await queryClient.cancelQueries({ queryKey: key });

  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);
  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        sections: oldWorkflows?.workflow?.sections?.map((section) => ({
          ...section,
          items: section?.items?.filter(
            (item) => item.id !== variables.workflowItemId,
          ),
        })),
      },
    });
  }
}

export const updateWorkflowStatusInCache = (
  queryClient: QueryClient,
  variables: UpdateWorkflowMutationVariables,
) => {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: variables.workflowId },
  ];
  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);
  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        status: variables.status as WorkflowStatus,
      },
    });
  }
};

export const saveWorkflowInCache = (
  queryClient: QueryClient,
  variables: SaveWorkflowMutationVariables,
) => {
  const key = [
    (workflowQueryDocument.definitions[0] as any).name.value,
    { id: variables.id },
  ];
  const oldWorkflows = queryClient.getQueryData<WorkflowQueryQuery>(key);
  if (oldWorkflows && oldWorkflows.workflow) {
    queryClient.setQueryData<WorkflowQueryQuery>(key, {
      workflow: {
        ...oldWorkflows.workflow,
        title: variables.title ? variables.title : oldWorkflows.workflow.title,
        description: variables.description
          ? variables.description
          : oldWorkflows.workflow.description,
        status: WorkflowStatus.Draft,
      },
    });
  }
};

export const invalidateWorkflowRequestsQuery = (
  id: string,
  queryClient: QueryClient,
) => {
  queryClient.removeQueries({
    queryKey: [
      (workflowRequestsQueryDocument.definitions[0] as any).name.value,
      { id },
    ],
  });
};
