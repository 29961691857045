// https://pspdfkit.com/api/web/
// https://github.com/PSPDFKit/awesome-pspdfkit
// make sure you have the PSPDFKit license key(VITE_PSPDFKIT_LICENSE_KEY) in your .env file

import { useEffect, useRef } from 'react';

import PSPDFKit, { Configuration, Instance, ToolbarItem } from 'pspdfkit';
import { readOnlyBasicToolbarItems } from './toolbarItems';

type PDFViewerProps = {
  /** The URL of the PDF document to be displayed */
  url: string;
  /** Don't allow annotations and editing
   * @default true
   * */
  readOnly?: boolean;
  /** This is a list of items to override the default toolbar items.
   * @default readOnlyBasicToolbarItems
   * */
  toolbarItems?: ToolbarItem[];
  /** PSPDFKit configuration options */
  configurationsOptions?: Configuration;
};

export default function PDFViewer({
  url,
  readOnly = true,
  toolbarItems,
  configurationsOptions,
}: PDFViewerProps) {
  const containerRef = useRef(null);
  useEffect(() => {
    const container = containerRef.current; // This `useRef` instance will render the PDF.

    let instance: Instance;
    // eslint-disable-next-line
    (async function () {
      PSPDFKit.unload(container); // Ensure that there's only one PSPDFKit instance.

      // https://pspdfkit.com/api/web/PSPDFKit.Configuration.html
      instance = await PSPDFKit.load({
        licenseKey: process.env.REACT_APP_PSPDFKIT_LICENSE_KEY,
        container: container as unknown as HTMLElement,
        document: url,
        baseUrl: `${window.location.protocol}//${window.location.host}/`,
        printMode: PSPDFKit.PrintMode.EXPORT_PDF, //https://pspdfkit.com/guides/web/print/print-modes/#pspdfkit-printmode-export_pdf
        ...configurationsOptions,
      });
      // https://pspdfkit.com/api/web/PSPDFKit.ViewState.html
      instance.setViewState((viewState) => viewState.set('readOnly', readOnly));

      // Expose the PSPDFKit instance to the window object.
      window.PSPDFKIT_INSTANCE = instance;

      // Set/remove/add custom toolbar items.
      // https://pspdfkit.com/api/web/PSPDFKit.Instance.html#setToolbarItems
      if (toolbarItems) {
        instance.setToolbarItems(toolbarItems);
      } else {
        instance.setToolbarItems(readOnlyBasicToolbarItems);
      }
    })();
    // cleanup the PSPDFKit instance
    return () => {
      window.PSPDFKIT_INSTANCE = undefined;
      PSPDFKit?.unload(container);
    };
  }, [configurationsOptions, readOnly, toolbarItems, url]);

  // This div element will render the document to the DOM.
  return (
    <div ref={containerRef} style={{ width: '100%', height: 'inherit' }} />
  );
}
