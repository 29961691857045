import {
  Typography,
  Select,
  MenuItem,
  Stack,
  Box,
  SelectChangeEvent,
  IconButton,
} from '@mui/material';
import { WorkflowItemType } from 'components/Requests/requests.types';
import { WorkflowItemFragment } from 'gql/graphql';
import {
  useDeleteWorkflowItem,
  useUpdateWorkflowItem,
  useUpdateWorkflowItemCondition2,
} from 'hooks/workflow-item-hooks';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { InputLabelStyled } from 'ui/Form/InputLabelStyled';
import { FormControlStyled } from 'ui/Form/FormControlStyled';
import { useRequestBuilderContext } from 'components/Requests/components/RequestsBuilder/context/RequestsBuilderContext';
import { useWorkflowItemInput } from 'components/Requests/components/RequestsBuilder/hooks/workflow-item-input-hook';
import { DeleteOutline } from '@mui/icons-material';
import { TOAST_FAILURE, TOAST_SUCCESS } from 'constants/constants';
import { AlertMessage } from 'utilities/utils';
import { TextFieldStyled } from 'ui/Form/TextFieldStyled';

export interface ConditionalQuestionEditorProps {
  label: string;
  item: WorkflowItemFragment;
  matchValue: string;
}

export const ConditionalQuestionEditor = ({
  label,
  item,
  matchValue,
}: ConditionalQuestionEditorProps) => {
  const queryClient = useQueryClient();

  const { mutate: updateWorkflowItemMutation } = useUpdateWorkflowItem();
  const { mutate: updateCondition } = useUpdateWorkflowItemCondition2();
  const { mutate: deleteWorkflowItemMutation } = useDeleteWorkflowItem();

  const { workflowId, setErrored, errors, isSubmitting, isErrored } =
    useRequestBuilderContext();

  const [itemType, setItemType] = useState<WorkflowItemType>(
    (item.type as WorkflowItemType) || WorkflowItemType.Boolean,
  );

  useEffect(() => {
    setItemType(item.type as WorkflowItemType);
  }, [item.type]);

  const deleteItem = () => {
    deleteWorkflowItemMutation(
      {
        workflowItemId: item.id,
      },
      {
        onSuccess: (response) => {
          if (response?.deleteWorkflowItem?.errors?.length) {
            AlertMessage(TOAST_FAILURE, 'Failed to delete condition');
          } else {
            AlertMessage(TOAST_SUCCESS, 'Condition deleted');
          }
        },
      },
    );
  };

  const onSubmit = useCallback(
    (newValue: string) => {
      if (!item || item.prompt === newValue) {
        return;
      }
      updateWorkflowItemMutation({
        workflowItemId: item?.id,
        prompt: newValue,
      });
    },
    [item, updateWorkflowItemMutation],
  );

  const inputProps = useWorkflowItemInput({ onSubmit });

  const handleTypeChange = ({ target: { value } }: SelectChangeEvent) => {
    setItemType(value as WorkflowItemType);
    updateWorkflowItemMutation({
      workflowItemId: item?.id,
      type: value as WorkflowItemType,
    });
  };

  useEffect(() => {
    const updateMatchValue = () => {
      const workflowItemConditionId = item.conditions?.[0]?.id;
      updateCondition({
        workflowItemConditionId,
        matchValue,
      });
    };

    if (
      item.conditions?.[0]?.matchValue !== null &&
      item.conditions?.[0]?.matchValue !== undefined &&
      matchValue !== item.conditions?.[0]?.matchValue
    ) {
      updateMatchValue();
    }
  }, [item, matchValue, queryClient, setErrored, updateCondition, workflowId]);

  const isDeleteDisabled = () => {
    if (isSubmitting || isErrored) return true;

    return false;
  };

  return (
    <Stack direction="row" spacing="5px" alignItems="top">
      <Box width="33px">
        <Typography sx={{ marginTop: 4.5 }} fontWeight={700}>
          {label}
        </Typography>
      </Box>
      <FormControlStyled
        sx={{ flexGrow: 3 }}
        error={Boolean(errors[`${item?.id}.type`])}
      >
        <InputLabelStyled>Type</InputLabelStyled>
        <Select
          value={itemType}
          defaultValue={WorkflowItemType.Boolean}
          onChange={handleTypeChange}
          aria-invalid={Boolean(errors[`${item?.id}.type`])}
          aria-errormessage="conditon__input--error"
          inputProps={{
            label: 'type',
          }}
        >
          <MenuItem value={WorkflowItemType.Boolean}>Y/N</MenuItem>
          <MenuItem value={WorkflowItemType.Text}>Short Answer</MenuItem>
          <MenuItem value={WorkflowItemType.Upload}>File Upload</MenuItem>
        </Select>
      </FormControlStyled>
      <TextFieldStyled
        sx={{ flexGrow: 10 }}
        error={Boolean(errors[item?.id])}
        label="Question"
        InputLabelProps={{ htmlFor: `${label} input` }}
        InputBaseProps={{
          type: 'text',
          id: `${label} input`,
          defaultValue: item.prompt,
          inputProps: { 'aria-label': 'Question input', ...inputProps },
          fullWidth: true,
          'aria-invalid': Boolean(errors[item?.id]),
          color: errors[item?.id] ? 'error' : undefined,
          'aria-errormessage': 'conditon__input--error',
        }}
        FormHelperTextProps={{ id: 'conditon__input--error' }}
        helperText={errors[item?.id] ?? errors[`${item?.id}.type`]}
      />
      <IconButton
        aria-label="Delete Conditional Question"
        sx={{ paddingX: '0px', alignSelf: errors[item?.id] ? 'top' : 'end' }}
        onClick={deleteItem}
        disabled={isDeleteDisabled()}
      >
        <DeleteOutline fontSize="small" />
      </IconButton>
    </Stack>
  );
};
