export type PaginationData = {
  page: number
  pages: number
  count: number
}

export type ServiceAlias = {
  id: number
  aliasable: {
    name: string
  }
  aliasable_id: number
  aliasable_type: string
  cpa_id: number
  created_at: string
  external_folder_path: string
  external_id: string
  service_id: string
  external_name: string
}

export type ServiceAliases = ServiceAlias[]

export const defaultPagination: PaginationData = {
  page: 0,
  pages: 0,
  count: 0
};

export const defaultServiceAlias: ServiceAlias = {
  id: 0,
  aliasable: {
    name: ''
  },
  aliasable_id: 0,
  aliasable_type: '',
  cpa_id: 0,
  created_at: '',
  external_folder_path: '',
  external_id: '',
  service_id: '',
  external_name: ''
}

export type ModalProps = {
  closeModal: () => void
  serviceAlias: ServiceAlias
  serviceAliasData: ServiceAlias[]
  setServiceAliasData: (serviceAliases: ServiceAlias[]) => void
}

export type SelectOption = {
  label: string
  value: number
}
