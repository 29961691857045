import { Dialog, Typography } from '@mui/material';
import PSPDFViewer from 'common/PSPDFKit/PSPDFViewer';
import { Header } from 'ui/Header/Header';

type Props = {
  url: string;
  open: boolean;
  headerTitle: string;
  onClose: () => void;
};

export default function FileDisplayFromUrl({
  url,
  open,
  headerTitle,
  onClose,
}: Props) {
  const isPdf = url.split('?')[0]?.endsWith('.pdf');

  return (
    <Dialog
      open={open}
      fullScreen
      PaperProps={{ sx: { width: 'calc(100vw - 200px)' } }}
      sx={{ zIndex: 1049 }}
      maxWidth="xl"
    >
      <Header onClose={onClose} title={headerTitle} />
      {url && isPdf && <PSPDFViewer url={url} />}
      {url && !isPdf && (
        <iframe title="preview" src={url} width="100%" height="1000px" />
      )}
      {!url && (
        <Typography
          variant="h1"
          color="GrayText"
          display="flex"
          paddingTop="2rem"
          justifyContent="center"
        >
          Preview unavailable
        </Typography>
      )}
    </Dialog>
  );
}
