/* eslint-disable */
/* react/no-array-index-key */
import React, { Component, PropTypes } from 'react';
import {
  Button,
  Label,
  FormGroup,
  DropdownMenu,
  DropdownItem,
  Input,
  Modal,
  ModalBody,
} from 'reactstrap';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import { SelectField } from 'common/CustomFields';
import UploadFiles from 'api/UploadFiles';
import moment from 'moment-timezone';
import Avatar from 'react-avatar';
import PdfViewer from 'components/PdfViewer/PdfViewer';
import FilePopup from 'components/Files/FilePopup';
import ConfirmationModal from 'common/ConfirmationModal';
import PageNotFound from 'common/PageNotFound';
import { FILENAME } from 'utilities/regularexpressions';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import * as Utilities from 'utilities/utils.js';
import * as Config from 'constants/constants';
import * as TaskApi from 'api/TaskApi';
import { AlertMessage, validateAddress } from 'utilities/utils.js';
import mime from 'mime';
import './design.scss';
import PSPDFViewer from 'common/PSPDFKit/PSPDFViewer';

const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);
const isIE = /* @cc_on!@ */ false || !!document.documentMode;
const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
class ShowDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      isEditDocument: false,
      filename: '',
      years: [],
      yearId: '',
      yearValue: '',
      selectedMultiTagRecords: [],
      mutiltagdropdownOpen: false,
      tags: [],
      tagId: '',
      tagName: '',
      inputMultiTagVal: '',
      newMultiTagList: [],
      loading: false,
      EditSubmit: false,
      editedTags: [],
      editedYear: null,
      editedFileName: null,
      copied: false,
      tokenUrl: '',
      aws_url: '',
      selectedItem: [],
      selectedAccount: '',
      allAccounts: [],
      deleteConfirmation: false,
      fileExt: '',
      errors: {},
      unauthorisedAccess: false,
      unauthorisedMessage: '',
      months: [],
      monthId: '',
      monthValue: '',
      editedMonth: null,
      updatedDownloadUrl: '',
      updatedFileUrl: '',
      propState: {},
      filterStatusModal: false,
      filterStatus: 'active',
      shareFile: false,
      fileShared: false,
      newFileSource: '',
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    const token = window.location.search;
    const time_now = Date.now();
    const interval = setInterval(() => {
      // TODO: __HOMEDATA-AUDIT
      const homeDataObj = Utilities.getStorageData();
      if (homeDataObj) {
        clearInterval(interval);
        const tagsData = homeDataObj
          ? homeDataObj.pref_type_options.upload_tags
          : [];
        const tagsCantAddRem = homeDataObj.is_employee
          ? homeDataObj.pref_type_options.tags_emp_cant_addrem
          : homeDataObj.pref_type_options.tags_client_cant_addrem;
        const selectedMultiTags = this.state.selectedMultiTagRecords;
        const request = axios.get(Config.SHOW_DOCUMENT_API_PATH + token);
        request.then((res) => {
          if (res.status === 200 && res.data.document) {
            const removeDuplicateTags = Utilities.removeDuplicateTags(
              res.data.document.tags,
            );
            const documentData = res.data.document;
            documentData.tags = removeDuplicateTags;
            const arhivedStatus = homeDataObj.is_employee
              ? documentData.is_file_archived_by_firm
              : documentData.is_file_archived_by_contact;
            const filterStatus = arhivedStatus ? 'archive' : 'active';
            this.setState({
              years: homeDataObj.pref_type_options.upload_file_year,
              months: homeDataObj.pref_type_options.upload_file_month
                ? homeDataObj.pref_type_options.upload_file_month
                : [],
              tags: tagsData,
              newMultiTagList: tagsData,
              yearId: documentData.upload_file_year,
              yearValue: documentData.upload_file_year,
              monthId: documentData.upload_file_month,
              monthValue: documentData.upload_file_month,
              selectedItem: documentData,
              propState: documentData,
              selectedAccount: {
                label: documentData.account_name,
                value: documentData.account_id,
              },
              filterStatus,
            });
            this.fileExtention(res.data.document.doc_name);
            removeDuplicateTags.map((item) => {
              for (let j = 0; j < tagsCantAddRem.length; j += 1) {
                if (tagsCantAddRem[j].key === item) {
                  selectedMultiTags.unshift({
                    tagId: tagsCantAddRem[j].key,
                    tagName: tagsCantAddRem[j].label,
                    closeIcon: false,
                  });
                }
              }
              for (let i = 0; i < tagsData.length; i += 1) {
                if (tagsData[i].key === item) {
                  selectedMultiTags.unshift({
                    tagId: tagsData[i].key,
                    tagName: tagsData[i].label,
                  });
                }
              }
              this.setState({ selectedMultiTagRecords: selectedMultiTags });
              return null;
            });
            this.setState({ loading: false });
            /* ************************************************* */
            if (
              (documentData.source.toLowerCase() === 'message' ||
                documentData.source.toLowerCase() === 'manual') &&
              documentData.contact_id
            ) {
              const contactId = documentData.contact_id;
              TaskApi.AccountsOfContact(this, contactId);
            }
            /* ************************************************* */
          } else if (res.status === 422) {
            this.unauthorised('(' + res.data.message + ')');
          } else {
            AlertMessage('error', 'document not found', 1000);
            this.props.navigate('/');
            clearInterval(interval);
          }
        });
      }
    }, 200);
  }
  onMultiTagFocus = () => {
    this.setState((prevState) => ({
      mutiltagdropdownOpen: true,
      tags: prevState.tags,
      tagId: '',
      tagName: '',
    }));
    if (this.state.errors.tags) {
      const err = this.state.errors;
      err.tags = '';
      this.setState({ errors: err });
    }
  };
  onButtonClose = () => {
    this.props.navigate('/');
  };
  getInitials(name) {
    if (name === undefined) {
      return null;
    }
    const initials =
      (name.split(' ')[0] ? name.split(' ')[0].charAt(0) : '') +
      (name.split(' ')[1] ? name.split(' ')[1].charAt(0) : '');
    return initials;
  }
  accountChange = (val) => {
    const { propState, errors } = this.state;
    const err = {};
    if (
      propState.source.toLowerCase() === 'manual' &&
      !propState.contact_id &&
      !val
    ) {
      err.account = 'Account is mandatory as no contact is tied to this file';
      this.setState({
        errors: err,
      });
      return false;
    }
    this.setState({
      selectedAccount: val || { label: '', value: '' },
      errors: {},
    });
  };
  unauthorised(message = '') {
    this.setState({ unauthorisedAccess: true, unauthorisedMessage: message });
  }
  fileExtention(item) {
    let m;
    let filenm;
    if (item.includes('.')) {
      const n = item.split('.').pop();
      m = '.' + n;
      filenm = item.substring(0, item.lastIndexOf('.'));
    } else {
      m = '';
    }
    this.setState({ fileExt: m, filename: filenm });
  }
  handleMultiTagChange = (val) => {
    const tagsArr = this.state.tags;
    const component = this;
    if (val) {
      component.setState({ tagId: val.key, tagName: val.label, errors: {} });
    } else {
      component.setState({ tagId: '', tagName: '', errors: {} });
    }

    const selectedInput = {
      tagId: val.key,
      tagName: val.label,
    };
    const selectedRecordsArr = this.state.selectedMultiTagRecords;
    const index = selectedRecordsArr.findIndex(
      (x) => x.tagId === selectedInput.tagId,
    );
    if (index === -1) {
      selectedRecordsArr.push(selectedInput);
      this.setState({ selectedMultiTagRecords: selectedRecordsArr });
    }
    this.setState({
      mutiltagdropdownOpen: false,
      tags: tagsArr,
      newMultiTagList: tagsArr,
      tagId: '',
      tagName: '',
    });
  };
  filterMultiTagSearch = (target) => {
    if (target) {
      const k = [];
      const value = target;
      const filter = value.toUpperCase();
      const tags = this.state.tags;
      tags.map((tag) => {
        for (let i = 0; i < target.length; i += 1) {
          if (tag.label.toUpperCase().indexOf(filter) > -1) {
            if (k.indexOf(tag) === -1) {
              k.push(tag);
            }
          }
        }
        return k;
      });
      this.setState({ newMultiTagList: k });
    } else {
      this.setState({ newMultiTagList: this.state.tags });
    }
  };
  handleEditDocumentCancel = () => {
    const component = this;
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = Utilities.getStorageData();
    const tagsData = homeDataObj.pref_type_options.upload_tags;
    const tagsCantAddRem = homeDataObj.is_employee
      ? homeDataObj.pref_type_options.tags_emp_cant_addrem
      : homeDataObj.pref_type_options.tags_client_cant_addrem;
    component.setState({
      loading: false,
      isEditDocument: false,
      mutiltagdropdownOpen: false,
      errors: {},
      newMultiTagList: this.state.tags,
    });
    if (!this.state.EditSubmit) {
      let tagg = [];
      const selectedMultiTags = [];
      if (component.state.editedTags.length > 0) {
        component.state.editedTags.map((item) => {
          for (let j = 0; j < tagsCantAddRem.length; j += 1) {
            if (tagsCantAddRem[j].key === item) {
              selectedMultiTags.unshift({
                tagId: tagsCantAddRem[j].key,
                tagName: tagsCantAddRem[j].label,
                closeIcon: false,
              });
            }
          }
          for (let i = 0; i < tagsData.length; i += 1) {
            if (tagsData[i].key === item) {
              selectedMultiTags.unshift({
                tagId: tagsData[i].key,
                tagName: tagsData[i].label,
              });
            }
          }
          tagg = selectedMultiTags;
          return null;
        });
      } else {
        component.state.selectedItem.tags.map((item) => {
          for (let j = 0; j < tagsCantAddRem.length; j += 1) {
            if (tagsCantAddRem[j].key === item) {
              selectedMultiTags.unshift({
                tagId: tagsCantAddRem[j].key,
                tagName: tagsCantAddRem[j].label,
                closeIcon: false,
              });
            }
          }
          for (let i = 0; i < tagsData.length; i += 1) {
            if (tagsData[i].key === item) {
              selectedMultiTags.unshift({
                tagId: tagsData[i].key,
                tagName: tagsData[i].label,
              });
            }
          }
          tagg = selectedMultiTags;
          return null;
        });
      }
      let yearr = null;
      if (component.state.editedYear !== null) {
        yearr = component.state.editedYear;
      } else {
        yearr = component.state.selectedItem.upload_file_year;
      }
      let monthh = null;
      if (component.state.editedMonth !== null) {
        monthh = component.state.editedMonth;
      } else {
        monthh = component.state.selectedItem.upload_file_month;
      }
      if (component.state.editedFileName !== null) {
        component.fileExtention(component.state.editedFileName);
      } else {
        component.fileExtention(component.state.selectedItem.doc_name);
      }
      component.setState({
        selectedMultiTagRecords: tagg,
        yearId: yearr,
        yearValue: yearr,
        monthId: monthh,
        monthValue: monthh,
        selectedAccount: {
          label: component.state.selectedItem.account_name,
          value: component.state.selectedItem.account_id,
        },
      });
    } else {
      let tagg = [];
      if (component.state.editedTags.length > 0) {
        const selectedMultiTags = [];
        component.state.editedTags.map((item) => {
          for (let j = 0; j < tagsCantAddRem.length; j += 1) {
            if (tagsCantAddRem[j].key === item) {
              selectedMultiTags.unshift({
                tagId: tagsCantAddRem[j].key,
                tagName: tagsCantAddRem[j].label,
                closeIcon: false,
              });
            }
          }
          for (let i = 0; i < tagsData.length; i += 1) {
            if (tagsData[i].key === item) {
              selectedMultiTags.unshift({
                tagId: tagsData[i].key,
                tagName: tagsData[i].label,
              });
            }
          }
          tagg = selectedMultiTags;
          return null;
        });
      }
      component.fileExtention(component.state.editedFileName);
      component.setState({
        selectedMultiTagRecords: tagg,
        yearId: component.state.editedYear,
        yearValue: component.state.editedYear,
        monthId: component.state.editedMonth,
        monthValue: component.state.editedMonth,
        selectedAccount: {
          label: component.state.propState.account_name,
          value: component.state.propState.account_id,
        },
      });
    }
  };
  handleEditDocumentSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      const tagg = [];
      this.state.selectedMultiTagRecords.map((tag) => {
        return tagg.push(tag.tagId);
      });
      const mydata = {
        multi_tags: tagg,
        document_ids: this.state.selectedItem.id,
        year: this.state.yearId,
        month: this.state.monthId,
        file_name: this.state.filename + this.state.fileExt,
        account_id: this.state.selectedAccount
          ? this.state.selectedAccount.value
          : '',
      };
      UploadFiles.updateSingleFile(mydata, this);
    }
  };
  changeMultiTagInput = () => {
    return false;
  };
  downloadAttachement = (url) => {
    window.location.href = url;
  };
  handleYearChange = (val) => {
    const component = this;
    if (val) {
      component.setState({
        yearId: parseInt(val.key, 10),
        yearValue: val.label,
        errors: {},
      });
    } else {
      component.setState({ yearId: '', yearValue: '' });
    }
  };
  handleMonthChange = (val) => {
    const component = this;
    if (val) {
      component.setState({
        monthId: val.key,
        monthValue: val.label,
        errors: {},
      });
    } else {
      component.setState({ monthId: '', monthValue: '' });
    }
  };
  removeSelectedMultiTagRecord = (tagId) => {
    const selectedRecordsArr = this.state.selectedMultiTagRecords;
    const index = selectedRecordsArr.findIndex((x) => x.tagId === tagId);
    if (index > -1) {
      selectedRecordsArr.splice(index, 1);
      this.setState({ selectedMultiTagRecords: selectedRecordsArr });
    }
  };
  showCopy = () => {
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 3000);
  };
  deleteConfirmationModal = (act = '') => {
    if (act === 'delete') {
      const component = this;
      const { selectedItem } = this.state;
      component.setState({ loading: true });
      const url = Config.DELETE_DOCUMENTS;
      const data = {
        document_ids: selectedItem.id,
      };
      const request = axios.put(url, data);
      request
        .then((response) => {
          if (response.data.status === 200) {
            component.setState({ loading: false });
            Utilities.AlertMessage('success', response.data.message, 3000);
            component.onButtonClose();
          } else {
            component.setState({ loading: false });
            Utilities.AlertMessage('error', response.data.message, 3000);
          }
        })
        .catch((error) => {
          component.setState({ loading: false });
          Utilities.AlertMessage(
            'error',
            'Some error occurred. Please try later.',
            3000,
          );
        });
    } else if (act === 'filterstatus') {
      const { filterStatus, selectedItem } = this.state;
      const ids = [];
      ids.push(selectedItem.id);
      const data = {
        id: ids,
      };
      if (filterStatus === 'active') {
        data.archive = 'true';
      }
      UploadFiles.archiveFile(this, data, 'previewFile');
    }
  };
  genLink = () => {
    const url =
      window.location.protocol +
      '//' +
      window.location.host +
      '?token=' +
      this.state.selectedItem.file_download_url;
    this.setState({ tokenUrl: url });
  };
  handleInputFieldChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () =>
      this.state.errors ? this.handleValidation() : null,
    );
  };
  handleValidation() {
    const filename = this.state.filename;
    const err = {};
    let formIsValid = true;
    if (!filename) {
      formIsValid = false;
      err.filename = 'Please enter document name';
    } else if (
      filename &&
      (!FILENAME.test(filename) || filename.includes('.'))
    ) {
      formIsValid = false;
      err.filename = 'Enter Valid document name';
    }
    if (this.state.selectedMultiTagRecords.length <= 0) {
      formIsValid = false;
      err.tags = 'at least 1 tag is mandatory';
    }
    this.setState({ errors: err });
    return formIsValid;
  }
  confirmDeletion = () => {
    const { source, is_recipient } = this.state.selectedItem;
    if (source === 'Multiple') {
      this.setState({
        deleteConfirmation: true,
        deleteAlertMessage:
          'Warning: The selected files will be deleted from all sources i.e Messages, Tasks etc.',
      });
    } else {
      this.setState({
        deleteConfirmation: true,
        deleteAlertMessage: 'Are you sure you want to delete this file?',
      });
    }
  };
  shareFile = () => {
    this.setState({
      shareFile: true,
    });
  };
  closeModal = (ishared = '', uniqueRecords = '') => {
    this.setState({ shareFile: false });
    if (ishared === 'shared' && uniqueRecords.length > 0) {
      const token = this.state.selectedItem.file_share_url;
      UploadFiles.particularDocumentData(this, token);
    }
  };
  renderMultiTags = () => {
    const selectedMultiTagRecords = this.state.selectedMultiTagRecords.map(
      (item) => {
        if (item.tagId) {
          return (
            <span className="tag tag--fill" key={item.tagId}>
              {item.tagName}
              {item.closeIcon === false ? null : (
                <i
                  className="icon-close2 remove"
                  onClick={() => this.removeSelectedMultiTagRecord(item.tagId)}
                />
              )}
            </span>
          );
        }
        return (
          <span className="tag tag--fill" key={item.tagId}>
            {item.tagName}{' '}
            {item.closeIcon === false ? null : (
              <i
                className="icon-close2 remove"
                onClick={() => this.removeSelectedMultiTagRecord(item.tagId)}
              />
            )}
          </span>
        );
      },
    );
    return selectedMultiTagRecords;
  };

  render() {
    // TODO: __HOMEDATA-AUDIT
    const storageData = Utilities.getStorageData();
    const {
      selectedItem,
      propState,
      errors,
      unauthorisedAccess,
      unauthorisedMessage,
      newMultiTagList,
      editedTags,
      loading,
      EditSubmit,
      updatedDownloadUrl,
      editedFileName,
      copied,
      isEditDocument,
      filename,
      allAccounts,
      selectedAccount,
      inputMultiTagVal,
      mutiltagdropdownOpen,
      tagId,
      tagName,
      years,
      yearId,
      yearValue,
      editedYear,
      months,
      monthId,
      monthValue,
      editedMonth,
      deleteConfirmation,
      updatedFileUrl,
      filterStatusModal,
      filterStatus,
      fileShared,
      uniqueRecords,
      newFileSource,
    } = this.state;
    const dateAdded = moment
      .tz(selectedItem.date_created, timeZone)
      .format('MM/DD/YYYY');
    if (unauthorisedAccess) {
      return <PageNotFound name="message" message={unauthorisedMessage} />;
    }
    const multiTags = newMultiTagList.map((item) => {
      return (
        <a
          key={item.key}
          onClick={() => this.handleMultiTagChange(item)}
          href="javascript:void(0)"
          className="dropdown-item"
        >
          {item.label}
        </a>
      );
    });
    let oldTags = '';
    if (selectedItem.tags !== undefined) {
      oldTags = selectedItem.tags.map((item) => {
        return (
          <span className="tag tag--fill" key={item}>
            {Utilities.tagLabel(item)}
          </span>
        );
      });
    }
    const newTags = editedTags.map((item) => {
      return (
        <span className="tag tag--fill" key={item}>
          {Utilities.tagLabel(item)}
        </span>
      );
    });
    if (loading) {
      return <div id="loading" style={loading ? blockStyle : noneStyle} />;
    }
    const getSize = selectedItem.file_size / 1024;
    const trimSize = getSize.toFixed(2);
    const url = EditSubmit
      ? updatedDownloadUrl
      : selectedItem.file_download_url;
    const fileUrl = EditSubmit ? updatedFileUrl : selectedItem.file_url;
    let recepent = selectedItem.recipient;
    let source = selectedItem.source;
    if (fileShared) {
      recepent = uniqueRecords;
      source = newFileSource;
    }
    return (
      <Modal className="Md-full-width" isOpen>
        <div className="eng-letter">
          <header>
            <div className="headerWrapper row">
              <div className="left-side col-md-8 col-12">
                <Button
                  className="btn-link btn--onlyicon btn btn-secondary"
                  onClick={this.onButtonClose}
                >
                  <i className="icon-back-arrow" aria-hidden="true" />
                </Button>
                <div className="head-inner d-block">
                  <h2>
                    {EditSubmit ? editedFileName : selectedItem.doc_name}
                    {selectedItem.is_private && (
                      <i className="icon-Lock text-dark ml-1" />
                    )}
                  </h2>
                  <h5>
                    Modified on{' '}
                    {moment
                      .tz(selectedItem.date_created, timeZone)
                      .format('MMM Do YYYY')}
                  </h5>
                </div>
              </div>
              <div className="right-side col-md-4 col-12">
                <div className="btn-wrap btn--leftspace">
                  <CopyToClipboard
                    text={
                      window.location.protocol +
                      '//' +
                      window.location.host +
                      '/document?token=' +
                      selectedItem.file_share_url
                    }
                    onCopy={this.showCopy}
                  >
                    <Button
                      className="btn btn-outline-light"
                      onClick={this.genLink}
                    >
                      {copied ? 'Copied' : 'Copy link'}
                    </Button>
                  </CopyToClipboard>
                  <Button
                    className="btn-link btn--onlyicon btn btn-secondary"
                    onClick={() => this.setState({ filterStatusModal: true })}
                    data-tip
                    data-for="archive_unarchive_icon"
                  >
                    <i
                      className={
                        filterStatus === 'archive'
                          ? 'icon-unarchive'
                          : 'icon-archive-line'
                      }
                      aria-hidden="true"
                    />
                    <ReactTooltip id="archive_unarchive_icon" effect="solid">
                      <span className="contactcard-team-member">
                        {filterStatus === 'archive' ? 'Unarchive' : 'Archive'}
                      </span>
                    </ReactTooltip>
                  </Button>
                  <Button
                    className="btn-link btn--onlyicon btn btn-secondary"
                    onClick={() => this.downloadAttachement(url)}
                    data-tip
                    data-for="download_icon"
                  >
                    <i className="icon-download2" aria-hidden="true" />
                    <ReactTooltip id="download_icon" effect="solid">
                      <span className="contactcard-team-member">Download</span>
                    </ReactTooltip>
                  </Button>
                  {Utilities.isUserEmployee() ||
                  (!Utilities.isUserEmployee() && selectedItem.is_private) ? (
                    <Button
                      className="btn-link btn--onlyicon btn btn-secondary"
                      onClick={this.confirmDeletion}
                      data-tip
                      data-for="delete_icon"
                    >
                      <i className="icon-delete-small" aria-hidden="true" />
                      <ReactTooltip id="delete_icon" effect="solid">
                        <span className="contactcard-team-member">Delete</span>
                      </ReactTooltip>
                    </Button>
                  ) : null}
                  <Button
                    className="btn-link btn--onlyicon btn btn-secondary"
                    onClick={() => this.setState({ isEditDocument: true })}
                    data-tip
                    data-for="edit_icon"
                  >
                    <i className="icon-edit" aria-hidden="true" />
                    <ReactTooltip id="edit_icon" effect="solid">
                      <span className="contactcard-team-member">Edit</span>
                    </ReactTooltip>
                  </Button>
                  <Button
                    className="btn-link btn--onlyicon btn btn-secondary line-right"
                    onClick={this.onButtonClose}
                    data-tip
                    data-for="close_icon"
                  >
                    <i className="icon-close2" aria-hidden="true" />
                    <ReactTooltip id="close_icon" effect="solid">
                      <span className="contactcard-team-member">Close</span>
                    </ReactTooltip>
                  </Button>
                </div>
              </div>
            </div>
          </header>
          <div className="main-sec">
            <div className="left-side-inner-sec no-padding">
              <div
                className="left-inner-content no-shadow no-padding"
                style={{ height: '100%' }}
              >
                {selectedItem.file_type === 'PDF' && isIE ? (
                  <PdfViewer file={selectedItem.file_url} />
                ) : selectedItem.file_type === 'PDF' && !isIE ? (
                  <PSPDFViewer url={fileUrl} />
                ) : (
                  <div
                    className="emptyBox text-center"
                    onClick={() => this.downloadAttachement(url)}
                  >
                    <h3 className="emptyBox-icon text-large">
                      <i className="icon-download2" /> Please download file to
                      view the details.
                    </h3>
                  </div>
                )}
              </div>
            </div>
            <div className="right-sidebar-inner">
              <form onSubmit={this.handleEditDocumentSubmit}>
                <div className="form-group form-inline-label">
                  <Label
                    for="forFileName"
                    className={
                      isEditDocument
                        ? 'align-self-start pt-2 mt-1'
                        : 'align-self-start'
                    }
                  >
                    File Name
                  </Label>
                  <div className="labelValue">
                    {isEditDocument ? (
                      <div
                        className={
                          errors.filename
                            ? 'd-block form-group form-error mb-0'
                            : 'd-block form-group mb-0'
                        }
                      >
                        <input
                          type="text"
                          name="filename"
                          className="form-control"
                          value={filename}
                          onChange={this.handleInputFieldChange}
                          error={errors.filename}
                          autoComplete="off"
                        />
                        <span
                          className={errors.filename ? 'error_label' : 'd-none'}
                        >
                          {errors.filename}
                        </span>
                      </div>
                    ) : (
                      <span>
                        {EditSubmit ? editedFileName : selectedItem.doc_name}
                      </span>
                    )}
                  </div>
                </div>
                <div className="form-group form-inline-label">
                  <Label for="forContact">UPLOADED BY</Label>
                  <div className="labelValue">
                    <div className="avatar avatar--w20">
                      {selectedItem.uploader ? (
                        selectedItem.uploader.avatar ? (
                          <img src={selectedItem.uploader.avatar} alt="" />
                        ) : selectedItem.uploader.initials ? (
                          <span className="uName">
                            {selectedItem.uploader.initials}
                          </span>
                        ) : (
                          '-'
                        )
                      ) : (
                        '-'
                      )}
                      <span className="userName">
                        {selectedItem.uploaded_by || '-'}
                      </span>
                    </div>
                  </div>
                </div>
                {Utilities.isUserEmployee() ? (
                  <div className="form-group form-inline-label">
                    <Label for="forContact" className="align-self-start">
                      Associated With
                    </Label>
                    <div className="labelValue">
                      <div className="avatar avatar--w20">
                        {recepent && recepent.length
                          ? recepent.map((each, index) => {
                              if (index < 4) {
                                return (
                                  <div
                                    data-tip
                                    data-for={`${each.initials}_${index}`}
                                    className="avatar avatar--w20 d-inline-block"
                                  >
                                    {each.name ? (
                                      each.avatar ? (
                                        <img
                                          src={each.avatar}
                                          alt="I"
                                          width="20"
                                          height="20"
                                          className="rounded-circle"
                                        />
                                      ) : (
                                        <span className="uName">
                                          {each.initials}
                                        </span>
                                      )
                                    ) : each.account_name ? (
                                      <span className="uName">
                                        {this.getInitials(each.account_name)}
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                    <ReactTooltip
                                      id={`${each.initials}_${index}`}
                                      effect="solid"
                                    >
                                      <span className="contactcard-team-member">
                                        {each.name +
                                          (each.account_name
                                            ? ' . ' + each.account_name
                                            : '')}
                                      </span>
                                    </ReactTooltip>
                                  </div>
                                );
                              } else if (index === 4) {
                                return (
                                  <div
                                    key={index}
                                    className="dropdown dropdown--small d-inline-block ml-0"
                                  >
                                    <a
                                      href="javascript:"
                                      className="btn p-0 dropdown-toggle border-0"
                                      data-toggle="dropdown"
                                      style={{ lineHeight: '20px' }}
                                    >
                                      <span className="avatar avatar--w20 align-self-center">
                                        <span className="uName">
                                          +{recepent.length - 4}
                                        </span>
                                      </span>
                                      <span className="dropupWrap--caret" />
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      {recepent.map((e, i) => {
                                        if (i >= 4) {
                                          return (
                                            <a
                                              key={recepent.indexOf(e)}
                                              href="javascript:"
                                              className="dropdown-item py-1"
                                            >
                                              <div className="d-flex">
                                                <span className="avatar avatar--w20 align-self-start">
                                                  <span className="uName">
                                                    {e.initials ||
                                                      this.getInitials(
                                                        e.account_name,
                                                      )}
                                                  </span>
                                                </span>
                                                <div className="">
                                                  <h5 className="fontSize letterSpace-1 mb-0">
                                                    {e.name}
                                                  </h5>
                                                  <h4 className="subHeadline text-light letterSpace-1 mb-0">
                                                    {e.account_name}
                                                  </h4>
                                                </div>
                                              </div>
                                            </a>
                                          );
                                        }
                                        return null;
                                      })}
                                    </div>
                                  </div>
                                );
                              }
                              return null;
                            })
                          : '-'}
                        {selectedItem.source === 'Note' ||
                        (selectedItem.source === 'Task' &&
                          selectedItem.task_type_key === 'to_do') ? null : (
                          <div className="mr-2 d-inline-block">
                            <a
                              href="javascript:;"
                              onClick={this.shareFile}
                              className="text-dark fontSize p-0"
                            >
                              <i className="icon-Share text-small mr-2 vertical-middle" />
                              Share
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="form-group form-inline-label">
                    <Label for="forContact" className="align-self-start" />
                    <div className="labelValue">
                      <div className="mr-2 d-inline-block">
                        <a
                          href="javascript:;"
                          onClick={this.shareFile}
                          className="text-dark fontSize p-0"
                        >
                          <i className="icon-Share text-small mr-2 vertical-middle" />
                          Share
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                <div className="form-group form-inline-label">
                  <Label for="forContact">Source</Label>
                  <div className="labelValue">
                    <div className="avatar">
                      <span className="userName">{source}</span>
                    </div>
                  </div>
                </div>
                <div className="form-group form-inline-label">
                  <Label for="forContact">Date Added</Label>
                  <div className="labelValue">
                    <div className="avatar">
                      <span className="userName">{dateAdded}</span>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    errors.tags
                      ? 'form-group form-inline-label form-error'
                      : 'form-group form-inline-label'
                  }
                >
                  <Label
                    for="forContact"
                    className={
                      isEditDocument
                        ? 'align-self-start pt-2 mt-1'
                        : 'align-self-start'
                    }
                  >
                    Tags
                  </Label>
                  <div className="labelValue">
                    {isEditDocument ? (
                      <div className="labelValue customTags mailDrodown customTagsDropDown">
                        <div className="customTags__input form-control">
                          {this.renderMultiTags()}
                          <label htmlFor="multitags" className="inputHelper" />
                          <Input
                            type="text"
                            name="multitags"
                            id="multitags"
                            onFocus={this.onMultiTagFocus}
                            onChange={this.changeMultiTagInput}
                            className="dropdown-toggle"
                            placeholder=""
                            value={inputMultiTagVal}
                            autoComplete="off"
                          />
                        </div>
                        {errors.tags ? (
                          <span className="error_label">
                            at least
                            <b className="label-medium"> 1 </b>
                            tag is mandatory
                          </span>
                        ) : null}
                        {mutiltagdropdownOpen ? (
                          <div className="dropdown-menu show tagsDropDown">
                            <div className="input-group">
                              <input
                                type="text"
                                value={
                                  tagId
                                    ? { value: tagId, label: tagName }
                                    : undefined
                                }
                                className="form-control border-0"
                                name="owner"
                                onChange={(e) =>
                                  this.filterMultiTagSearch(e.target.value)
                                }
                                id="myInput"
                              />
                              <span className="input-group-addon border-left-0">
                                <i className="icon-zoom2" />
                              </span>
                            </div>
                            {multiTags}
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="Tag-span-Value">
                        <span className="userName">
                          {EditSubmit ? newTags : oldTags}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group form-inline-label">
                  <Label for="forContact">Size</Label>
                  <div className="labelValue">
                    <div className="avatar">
                      <span className="userName">{trimSize} KB</span>
                    </div>
                  </div>
                </div>
                <div className="form-group form-inline-label">
                  <Label for="forContact">kind</Label>
                  <div className="labelValue">
                    <div className="avatar">
                      <span className="userName">
                        {selectedItem.content_type
                          ? mime.getExtension(selectedItem.content_type)
                          : '-'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group form-inline-label">
                  <Label
                    for="forContact"
                    className={
                      isEditDocument
                        ? 'align-self-start pt-2 mt-1'
                        : 'align-self-start'
                    }
                  >
                    Year
                  </Label>
                  <div className="labelValue">
                    {isEditDocument ? (
                      <Select
                        id="state-select"
                        className="select-custom-class"
                        onBlurResetsInput={false}
                        onSelectResetsInput={false}
                        autoFocus
                        options={years}
                        onChange={this.handleYearChange}
                        value={
                          yearId ? { value: yearId, label: yearValue } : null
                        }
                        isClearable={true}
                      />
                    ) : (
                      <div className="avatar">
                        <span className="userName">
                          {EditSubmit
                            ? editedYear
                            : selectedItem.upload_file_year}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group form-inline-label">
                  <Label
                    for="forContact"
                    className={
                      isEditDocument
                        ? 'align-self-start pt-2 mt-1'
                        : 'align-self-start'
                    }
                  >
                    Month
                  </Label>
                  <div className="labelValue">
                    {isEditDocument ? (
                      <Select
                        id="state-select"
                        className="select-custom-class"
                        onBlurResetsInput={false}
                        onSelectResetsInput={false}
                        autoFocus
                        options={months}
                        onChange={this.handleMonthChange}
                        value={
                          monthId ? { value: monthId, label: monthValue } : null
                        }
                        isClearable={true}
                      />
                    ) : (
                      <div className="avatar">
                        <span className="userName">
                          {EditSubmit
                            ? editedMonth
                            : selectedItem.upload_file_month}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                {isEditDocument ? (
                  <div className="form-group formbtn">
                    <div className="btn-wrap btn--leftspace">
                      <Button
                        type="button"
                        className="btn btn-outline-light"
                        color="primary"
                        onClick={this.handleEditDocumentCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        color="primary"
                      >
                        Save Changes
                      </Button>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </form>
              {this.state.shareFile && (
                <FilePopup
                  shareFile
                  sharedFileDetails={[selectedItem]}
                  closeModal={this.closeModal}
                />
              )}
            </div>
            <ConfirmationModal
              isOpen={deleteConfirmation}
              headerText="Confirm deleting this file"
              messageText={this.state.deleteAlertMessage || ''}
              noBtnClick={() => this.setState({ deleteConfirmation: false })}
              yesBtnClick={() => this.deleteConfirmationModal('delete')}
              yesBtnText="Delete File"
              noBtnText="Cancel"
            />
            <ConfirmationModal
              isOpen={filterStatusModal}
              headerText={`Confirm ${
                filterStatus === 'archive' ? 'unarchiving' : 'archiving'
              } this file`}
              messageText={`Are you sure you want to ${
                filterStatus === 'archive' ? 'unarchive' : 'archive'
              } this file?`}
              noBtnClick={() => this.setState({ filterStatusModal: false })}
              yesBtnClick={() => this.deleteConfirmationModal('filterstatus')}
              yesBtnText={`${
                filterStatus === 'archive' ? 'Unarchive' : 'Archive'
              } File`}
              noBtnText="Cancel"
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default ShowDocument;
